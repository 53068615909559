import { useEffect } from "react";
import styles from "./LandingPage.module.css";

const LandingPage = () => {
  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add(styles.animate);
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);

  return (
    <div className={styles.landingPage}>
      <div className={styles.groupParent}>
        <div className={styles.sophieknits300x400pxLogoParent}>
          <img
            className={styles.sophieknits300x400pxLogoIcon}
            alt=""
            src="../sophieknits-300x400px-logo@2x.png"
          />
          <img
            className={styles.sophieknits300x400pxLogoIcon1}
            alt=""
            src="../sophieknits-300x400px-logo@2x.png"
          />
          <div className={styles.first}>
            <div className={styles.sophieknits}>
              <div className={styles.sophieknits300x400pxLogo}>
                <img
                  className={styles.sophieknits300x400pxLogoIcon2}
                  alt=""
                  src="../sophieknits-300x400px-logo@2x.png"
                />
              </div>
            </div>
            <div className={styles.comingSoon}>
              <div className={styles.comingSoonChild} />
              <b className={styles.comingSoon1}>COMING SOON</b>
            </div>
          </div>
        </div>
        <img className={styles.groupChild} alt="" src="../group-7@2x.png" />
        <div className={styles.sophieknits300x400pxLogoGroup}>
          <img
            className={styles.sophieknits300x400pxLogoIcon3}
            alt=""
            src="../sophieknits-300x400px-logo@2x.png"
          />
          <img
            className={styles.sophieknits300x400pxLogoIcon3}
            alt=""
            src="../sophieknits-300x400px-logo@2x.png"
          />
          <img
            className={styles.sophieknits300x400pxLogoIcon3}
            alt=""
            src="../sophieknits-300x400px-logo@2x.png"
          />
        </div>
      </div>
      <h1 className={styles.comingSoonWrapper} data-animate-on-scroll>
        <h1 className={styles.comingSoon2} data-animate-on-scroll>
          COMING SOON
        </h1>
      </h1>
    </div>
  );
};

export default LandingPage;
