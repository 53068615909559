import { useEffect } from "react";
import styles from "./LandingPage1.module.css";

const LandingPage1 = () => {
  useEffect(() => {
    const scrollAnimElements = document.querySelectorAll(
      "[data-animate-on-scroll]"
    );
    const observer = new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            const targetElement = entry.target;
            targetElement.classList.add(styles.animate);
            observer.unobserve(targetElement);
          }
        }
      },
      {
        threshold: 0.15,
      }
    );

    for (let i = 0; i < scrollAnimElements.length; i++) {
      observer.observe(scrollAnimElements[i]);
    }

    return () => {
      for (let i = 0; i < scrollAnimElements.length; i++) {
        observer.unobserve(scrollAnimElements[i]);
      }
    };
  }, []);

  return (
    <div className={styles.landingPage1}>
      <section className={styles.groupParent}>
        <div className={styles.sophieknitInstagramOrangeParent}>
          <div className={styles.sophieknitInstagramOrange}>
            <div className={styles.comingSoon}>
              <div className={styles.comingSoonChild} />
              <b className={styles.comingSoon1}>COMING SOON</b>
            </div>
            <img
              className={styles.x400pxsolidbackgroundIcon}
              alt=""
              src="../300x400pxsolidbackground@2x.png"
            />
          </div>
          <div className={styles.sophieknitInstagram} id="Instagram">
            <div className={styles.comingSoon}>
              <div className={styles.comingSoonChild} />
              <b className={styles.comingSoon1}>COMING SOON</b>
            </div>
            <img
              className={styles.x400pxsolidbackgroundIcon}
              alt=""
              src="../300x400pxsolidbackground@2x.png"
            />
          </div>
          <div className={styles.sophieknitInstagram1} id="Instagram">
            <div className={styles.comingSoon}>
              <div className={styles.comingSoonChild} />
              <b className={styles.comingSoon1}>COMING SOON</b>
            </div>
            <img
              className={styles.x400pxsolidbackgroundIcon}
              alt=""
              src="../300x400pxsolidbackground@2x.png"
            />
          </div>
        </div>
        <div className={styles.sophieknitInstagramOrangeParent}>
          <div className={styles.sophieknitInstagramBlue}>
            <div className={styles.comingSoon}>
              <div className={styles.rectangleDiv} />
              <b className={styles.comingSoon1}>COMING SOON</b>
            </div>
            <img
              className={styles.x400pxsolidbackgroundIcon}
              alt=""
              src="../300x400pxsolidbackground@2x.png"
            />
          </div>
          <div className={styles.sophieknitInstagram2} id="Instagram">
            <div className={styles.comingSoon}>
              <div className={styles.comingSoonChild} />
              <b className={styles.comingSoon1}>COMING SOON</b>
            </div>
            <img
              className={styles.x400pxsolidbackgroundIcon}
              alt=""
              src="../300x400pxsolidbackground@2x.png"
            />
          </div>
          <div className={styles.sophieknitInstagram3} id="Instagram">
            <div className={styles.comingSoon}>
              <div className={styles.comingSoonChild} />
              <b className={styles.comingSoon1}>COMING SOON</b>
            </div>
            <img
              className={styles.x400pxsolidbackgroundIcon}
              alt=""
              src="../300x400pxsolidbackground@2x.png"
            />
          </div>
        </div>
        <div className={styles.sophieknitInstagramOrangeParent}>
          <div className={styles.sophieknitInstagramPink}>
            <div className={styles.comingSoon}>
              <div className={styles.comingSoonChild3} />
              <b className={styles.comingSoon1}>COMING SOON</b>
            </div>
            <img
              className={styles.x400pxsolidbackgroundIcon}
              alt=""
              src="../300x400pxsolidbackground@2x.png"
            />
          </div>
          <div className={styles.sophieknitInstagram4} id="Instagram">
            <div className={styles.comingSoon}>
              <div className={styles.comingSoonChild} />
              <b className={styles.comingSoon1}>COMING SOON</b>
            </div>
            <img
              className={styles.x400pxsolidbackgroundIcon}
              alt=""
              src="../300x400pxsolidbackground@2x.png"
            />
          </div>
          <div className={styles.sophieknitInstagram5}>
            <div className={styles.comingSoon}>
              <div className={styles.comingSoonChild} />
              <b className={styles.comingSoon1}>COMING SOON</b>
            </div>
            <img
              className={styles.x400pxsolidbackgroundIcon}
              alt=""
              src="../300x400pxsolidbackground@2x.png"
            />
          </div>
        </div>
      </section>
      <a
        className={styles.instagramBanner}
        href="https://www.instagram.com/sophieknits_/"
        target="_blank"
        data-animate-on-scroll
      >
        <b className={styles.instagram} data-animate-on-scroll>
          Instagram
        </b>
        <img
          className={styles.instagramLogoWhiteIcon}
          alt=""
          src="../instagram-logo-white@2x.png"
        />
      </a>
    </div>
  );
};

export default LandingPage1;
