import { useCallback } from "react";
import styles from "./Homepage.module.css";

const Homepage = () => {
  const onHomepageButtonClick = useCallback(() => {
    const anchor = document.querySelector("[data-scroll-to='component62']");
    if (anchor) {
      anchor.scrollIntoView({ block: "start" });
    }
  }, []);

  return (
    <div className={styles.homepage}>
      <button className={styles.homepageButton} onClick={onHomepageButtonClick}>
        <button className={styles.rectangle} />
        <p className={styles.about}>About</p>
      </button>
      <a
        className={styles.contactWrapper}
        href="https://www.instagram.com/sophieknits_/"
        target="_blank"
      >
        <b className={styles.contact}>Instagram</b>
      </a>
      <button className={styles.project}>
        <div className={styles.projectChild} />
        <b className={styles.projects}>Projects</b>
      </button>
      <a
        className={styles.contactContainer}
        href="mailto:hello@sophieknits.com?subject=Hello from Website"
      >
        <b className={styles.contact1}>Contact</b>
      </a>
      <img className={styles.img0064Icon} alt="" src="../Pile@1x.png" />
      <img
        className={styles.img0064Icon1}
        alt=""
        src="../FlowersBalloon@1x.png"
      />
      <img className={styles.img0064Icon2} alt="" src="../img-0064@2x.png" />
      <img className={styles.img0064Icon3} alt="" src="../img-00641@2x.png" />
      <img className={styles.img0064Icon4} alt="" src="../img-00642@2x.png" />
      <img
        className={styles.sophieknits300x400pxLogoIcon}
        alt=""
        src="../sophieknits-300x400px-logo@2x.png"
      />
      <section className={styles.component72} id="About">
        <h1
          className={styles.component62}
          id="About"
          data-scroll-to="component62"
        >
          <b className={styles.about1}>About</b>
        </h1>
        <div className={styles.aFreelanceDesignerContainer}>
          <p className={styles.aFreelanceDesigner}>
            A freelance designer, knitter by night (and day too, who am I
            kidding). I knit slow fashion woollies and homeware. I sell custom
            beanies and cards.
          </p>
          <p
            className={styles.aFreelanceDesigner}
          >{`I started knitting in 2018 after asking my Granny to teach me. Long story short; I haven’t put my needles (& crochet hook) down since.`}</p>
        </div>
        <img className={styles.img2998Icon} alt="" src="../img-2998@2x.png" />
      </section>
    </div>
  );
};

export default Homepage;
