import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import LandingPage1 from "./pages/LandingPage1";
import Homepage from "./pages/Homepage";
import IPhone1413122 from "./pages/IPhone1413122";
import LandingPage from "./pages/LandingPage";
import IPhone1413121 from "./pages/IPhone1413121";
import { useEffect } from "react";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
      case "/homepage":
        title = "";
        metaDescription = "";
        break;
      case "/iphone-14-13-12-2":
        title = "";
        metaDescription = "";
        break;
      case "/landing-page":
        title = "";
        metaDescription = "";
        break;
      case "/iphone-14-13-12-1":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<LandingPage1 />} />

      <Route path="/homepage" element={<Homepage />} />

      <Route path="/iphone-14-13-12-2" element={<IPhone1413122 />} />

      <Route path="/landing-page" element={<LandingPage />} />

      <Route path="/iphone-14-13-12-1" element={<IPhone1413121 />} />
    </Routes>
  );
}
export default App;
